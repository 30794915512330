<template>
  <v-row justify="center">
    <v-col
      cols="12"
      md="10"
    >
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2 font-weight-light">
            Edit Profile
          </div>

          <div class="subtitle-1 font-weight-light">
            Complete your profile
          </div>
        </template>

        <form autocomplete="off">
          <ValidationObserver v-slot="{invalid}">
            <v-row justify="center">
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="First name"
                    v-model="form.first_name"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="Last name"
                    v-model="form.last_name"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="Email"
                    :value="form.email"
                    disabled
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <ValidationProvider name="password" rules="min:6|password:@confirm" v-slot="{ errors }">
                  <v-text-field
                    label="New password"
                    type="password"
                    v-model="form.password"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <ValidationProvider name="confirm" rules="min:6" v-slot="{ errors }">
                  <v-text-field
                    label="Password confirm"
                    type="password"
                    v-model="form.password2"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="success"
                  class="mr-0"
                  :disabled="invalid"
                  @click="onSubmit"
                >
                  Update Profile
                </v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </form>
      </base-material-card>
      <v-snackbar
          v-model="snackbar"
          :timeout="2000"
          color="success"
      >
        Profile updated

        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
  import User from "@/models/User";

  export default {
    name: 'ProfileSettings',
    data() {
      return {
        snackbar: false,
        form: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password2: ''
        }
      }
    },
    computed: {
      account() {
        return User.query().first()
      }
    },
    async mounted() {
      await User.api().get('/auth/users/me/')
      this.form.first_name = this.account?.first_name
      this.form.last_name = this.account?.last_name
      this.form.email = this.account?.email
    },
    methods: {
      async onSubmit() {
        await User.api().post(`/api/v1/accounts/user/`, this.form)
        this.snackbar = true;
      }
    }
  }
</script>
